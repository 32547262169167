.initiative-section {
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: white;
    scroll-snap-align: start;
    justify-content: center;
    align-items: center;
}

/* Image */
.initiative-image-content {
    width: 50%;
    max-width: 100%;
    text-align: center;
}

.initiative-image1 {
    width: 80%;
    height: auto;
    box-shadow: 5px 5px 10px 5px black;
}

/* Text */
.initiative-text-content {
    width: 50%;
}

.initiative-title {
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
    color: #235B5B;
    padding-left: 20px;
    padding-bottom: 20px;
}

.initiative-title>span {
    position: relative;
}

.initiative-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #6FB7B9;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.initiative-description {
    margin: 32px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .6px;
    line-height: 1.5rem;
}

.initiative-text-title {
    margin: 0 0 24px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .7px;
}

/* Steps */
.initiative-text-step {
    margin: 16px 0;
}

.initiative-text-sTitle {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .7px;
}

.initiative-text-description {
    margin: 8px 24px;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .7px;
    line-height: 1.6rem;
}

.initiative-fa-icon {
    color: #235B5B;
}

/* Responsive */
@media screen and (max-width: 1100px) {
    .initiative-section {
        display: block;
    }

    .initiative-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .initiative-image1 {
        width: 100%;
        height: 100%;
    }

    .initiative-text-content {
        width: 100%;
    }

    .initiative-title {
        padding-left: 0;
    }

    .initiative-text-description {
        margin: 0;
    }
}

@media screen and (max-width: 900px) {
    .initiative-section {
        display: block;
    }

    .initiative-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .initiative-image1 {
        width: 50%;
        height: 50%;
    }

    .initiative-text-content {
        width: 100%;
    }

    .initiative-title {
        padding-left: 0;
    }

    .initiative-text-description {
        margin: 0;
    }
}

@media screen and (max-width: 500px) {
    .initiative-section {
        display: block;
        padding-top: 20%;
    }

    .initiative-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .initiative-image1 {
        width: 50%;
        height: 50%;
    }

    .initiative-text-content {
        width: 100%;
    }

    .initiative-title {
        padding-left: 0;
    }

    .initiative-text-description {
        margin: 0;
    }
}

@media screen and (max-width: 400px) {
    .initiative-section {
        display: block;
        padding-top: 20%;
    }

    .initiative-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .initiative-image1 {
        width: 50%;
        height: 50%;
    }

    .initiative-text-content {
        width: 100%;
    }

    .initiative-title {
        padding-left: 0;
    }

    .initiative-text-description {
        margin: 0;
    }
}

@media screen and (max-width: 300px) {
    .initiative-section {
        display: block;
        padding-top: 20%;
    }

    .initiative-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .initiative-image1 {
        width: 50%;
        height: 50%;
    }

    .initiative-text-content {
        width: 100%;
    }

    .initiative-title {
        padding-left: 0;
    }

    .initiative-text-description {
        margin: 0;
    }
}