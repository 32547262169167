.navbar-section {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: #3B9C9C;
    position: fixed;
    scroll-snap-align: start;
    z-index: 999;
}

.navbar-title a {
    color: white;
    letter-spacing: .6px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.navbar-title {
    font-family: Helvetica;
}

.navbar-sign {
    color: #54de54;
    font-family: Helvetica;
    font-size: 30px;
    font-weight: bold;
}

.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    min-width: 700px;
    font-family: Helvetica;
}

.navbar-items>li {
    list-style-type: none;
}

.navbar-links {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: .8px;
}

.navbar-links:hover {
    color: #6FB7B9;
}

.navbar-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: Helveticaf;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

.navbar-btn:hover {
    color: #1A8EFD;
    background-color: white;
    border: 1px solid #1A8EFD;
}

.logo-image {
    width: 7%;
    height: auto;
}

/* Hamburger Icon */
.mobile-nav {
    display: none;
}

.hamb-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.hamb-icon:hover {
    color: #0cc2ea;
}

/* Mobile Navbar */
.mobile-navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: white;
    z-index: 20;
    justify-content: center;
    align-items: center;
    transition: left .5s ease-in-out;
}

.mobile-navbar-close {
    position: absolute;
    top: 28px;
    right: 28px;
}

.mobile-navbar-close .hamb-icon:hover {
    color: rgb(255, 22, 22);
}

.open-nav {
    left: 0;
}

.mobile-navbar-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    gap: 24px;
    text-align: center;
}

.mobile-navbar-links li a {
    text-decoration: none;
    color: #235B5B;
    font-family: Helvetica;
    font-weight: bold;
    letter-spacing: .8px;
    transition: color .3s ease-in-out;
}

.mobile-navbar-links li a:hover {
    color: #0cc2ea;
}

/* Responsive */
@media screen and (max-width: 900px) {

    .navbar-btn,
    .navbar-items {
        display: none;
    }

    .mobile-nav {
        display: block;
    }
}