.hero-section {
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
    scroll-snap-align: start;
}

/* Text section */
.text-section {
    width: 50%;
    padding: 0 32px;
}

.text-headline {
    margin-bottom: 12px;
    color: rgb(77, 75, 75);
    font-family: Helvetica;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
}

.text-title {
    /* width: 500px; */
    color: #235B5B;
    font-family: Helvetica;
    font-size: 35px;
    font-weight: bold;
}

.text-descritpion {
    /* width: 400px; */
    margin: 14px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .5px;
    line-height: 1.4rem;
}

.text-appointment-btn {
    /* float: right; */
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: Helvetica;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

.text-appointment-btn:hover {
    color: #6FB7B9;
    background-color: transparent;
    border: 1px solid #6FB7B9;
}

.text-stats {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
}

.text-stats-container {
    text-align: center;
}

.text-stats-container>p {
    color: rgb(77, 75, 75);
    font-family: Helvetica;
    font-size: 22px;
    letter-spacing: .7px;
}

.text-stats-container p:first-child {
    margin-bottom: 8px;
    color: #1A8EFD;
    font-family: Helvetica;
    font-size: 32px;
    font-weight: bold;
}

/* Image section */
.hero-image-section {
    width: 50%;
    max-width: 100%;
    text-align: center;
}

.hero-image1 {
    width: 100%;
    height: auto;
    box-shadow: 5px 5px 10px 5px black;
}

/* Scroll Btn */
.scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background-color: #3B9C9C;
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
}

.show-scroll {
    display: flex;
}

/* Responsive */
@media screen and (max-width: 900px) {
    .hero-section {
        display: block;
        padding-top: 20;
    }

    .hero-image1 {
        width: 50%;
        height: 50%;
    }

    .hero-image-section {
        width: 100%;
        margin: 0 0 32px 0;
    }

    .text-section {
        width: 100%;
        padding: 0;
    }

    .text-title,
    .text-descritpion {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .hero-section {
        display: block;
        padding-top: 30%;
    }

    .hero-image1 {
        width: 50%;
        height: 50%;
    }

    .hero-image-section {
        width: 100%;
        margin: 0 0 32px 0;
    }

    .text-headline {
        font-size: 20px;
    }

    .text-title {
        font-size: 40px;
        text-align: center;
    }

    .text-descritpion {
        font-size: 20px;
        text-align: center;
        padding: 10px;
    }

    .text-appointment-btn {
        font-size: 16px;
    }

    .text-stats {
        gap: 18px;
    }

    .text-stats-container>p {
        font-size: 16px;
    }

    .text-stats-container p:first-child {
        font-size: 22px;
    }

    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
}