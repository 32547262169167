.effort-section {
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
}

.effort-container {
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
    padding-bottom: 10%;
}

.effort-title-content {
    margin: 64px 0 128px 0;
}

.effort-title {
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
    color: #235B5B;

}

.effort-title>span {
    position: relative;
}

.effort-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #6FB7B9;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.effort-description {
    margin: 64px;
    text-align: left;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .8px;
    line-height: 1rem;
}

/* Cards */
.effort-cards-content {
    margin: 64px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}

.effort-cards {
    height: auto;
    border: 2px solid #d7d3d3;
    border-radius: 12px;
    background-color: white;
    position: relative;
}

.effort-card-image {
    width: 60%;
    padding-top: 20px;
}

.effort-card-title {
    margin: 32px 0 40px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: .8px;
    height: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.effort-card-description {
    margin-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 24px;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .8px;
    line-height: 1.4rem;
    text-align: left;
}

.effort-card-icon {
    position: absolute;
    top: -24px;
    left: 44%;
    padding: 10px;
    background-color: #DCE6FF;
    border-radius: 30px;
}

.effort-fa-icon {
    color: #235B5B;
    font-size: 24px;
}

/* Responsive */
@media screen and (max-width: 1000px) {
    .effort-description {
        margin: 64px 32px;
    }

    .effort-cards-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 700px) {

    .effort-section {
        padding-top: 20%;
    }

    .effort-description {
        margin: 64px 8px;
        text-align: justify;
    }

    .effort-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 700px) {
    .effort-section {
        padding-top: 20%;
    }

    .effort-description {
        margin: 64px 8px;
        text-align: justify;
    }

    .effort-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
}