.donate-section {
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.donate-contribute {
    margin: 32px 0;
    color: #235B5B;
    font-family: Helvetica !important;
    font-size: 16px;
    letter-spacing: .5px;
    line-height: 1.6rem;
}

.donate-container {
    /* padding: 32px; */
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
}

/* Image */
.donate-image-content {
    width: 50%;
    max-width: 100%;
    text-align: center;
}

.donate-image1 {
    width: 80%;
    height: auto;
    box-shadow: 5px 5px 10px 5px black;
}

.donate-stripe {
    width: 40%;
    height: auto;
}

/* Text */
.donate-text-content {
    width: 50%;
}

.donate-title {
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
    color: #235B5B;
}

.donate-title>span {
    position: relative;
}

.donate-description2>a {
    color: #235B5B;
    font-weight: bold;
    text-shadow: 2px 2px #ffffff;
    text-decoration: none;
}

.donate-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #6FB7B9;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.donate-description {
    margin: 32px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .6px;
    line-height: 1.5rem;
}

.donate-text-title {
    margin: 0 0 24px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .7px;
}

/* Steps */
.donate-text-step {
    margin: 16px 0;
}

.donate-text-sTitle {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .7px;
}

.donate-text-description {
    margin: 8px 24px;
    color: #235B5B;
    font-family: Helvetica;
    letter-spacing: .7px;
    line-height: 1.6rem;
}

.donate-description2 {
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .6px;
    line-height: 1.5rem;
    padding: 0 10% 0 10%;
}

.fa-icon {
    color: #9D2553;
}

/* Responsive */
@media screen and (max-width: 1000px) {
    .donate-section {
        display: block;
    }

    .donate-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .donate-image1 {
        width: 50%;
        height: 50%;
    }

    .donate-text-content {
        width: 100%;
    }

    .donate-description2 {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media screen and (max-width: 500px) {
    .donate-section {
        display: block;
        padding-top: 20%;
    }

    .donate-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .donate-image1 {
        width: 50%;
        height: 50%;
    }

    .donate-text-content {
        width: 100%;
    }

    .donate-description2 {
        padding-left: 10%;
        padding-right: 10%;
    }
}