.stripe-section {
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.stripe-contribute {
    margin: 32px 0;
    color: #235B5B;
    font-family: Helvetica !important;
    font-size: 16px;
    letter-spacing: .5px;
    line-height: 1.6rem;
    text-align: left;
    color: #3B9C9C;
}

.stripe-container {
    /* padding: 32px; */
    background: white;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
}

/* Image */
.stripe-image-content {
    width: 50%;
    max-width: 100%;
    text-align: center;
}

.stripe-image1 {
    width: 80%;
    height: auto;
    box-shadow: 5px 5px 10px 5px black;
}

.stripe-stripe {
    width: 50%;
    height: auto;
}

/* Text */
.stripe-text-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stripe-title {
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
    color: #235B5B;
}

.stripe-title>span {
    position: relative;
}

.stripe-description2>a {
    color: #235B5B;
    font-weight: bold;
    text-shadow: 2px 2px #ffffff;
    text-decoration: none;
}

.stripe-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #6FB7B9;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.stripe-description {
    margin: 32px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .6px;
    line-height: 1.5rem;
}

.stripe-text-title {
    margin: 0 0 24px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .7px;
}

/* Steps */
.stripe-text-step {
    margin: 16px 0;
}

.stripe-text-sTitle {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .7px;
}

.stripe-text-description {
    margin: 8px 24px;
    color: #235B5B;
    font-family: Helvetica;
    letter-spacing: .7px;
    line-height: 1.6rem;
}

.stripe-description2 {
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .6px;
    line-height: 1.5rem;
    padding: 0 10% 0 10%;
}

.fa-icon {
    color: #9D2553;
}

/* Responsive */
@media screen and (max-width: 1000px) {
    .stripe-section {
        display: block;
    }

    .stripe-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .stripe-image1 {
        width: 100%;
        height: 100%;
    }

    .stripe-text-content {
        width: 100%;
    }

    .stripe-description2 {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media screen and (max-width: 500px) {
    .stripe-section {
        display: block;
        padding-top: 20%;
    }

    .stripe-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .stripe-image1 {
        width: 100%;
        height: 100%;
    }

    .stripe-text-content {
        width: 100%;
    }

    .stripe-description2 {
        padding-left: 10%;
        padding-right: 10%;
    }
}