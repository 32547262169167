
/* Styles No Requireds */
.role-container {
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
}

.mandatory {
    height: 80vh;
    width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    display: flex;
}

.card {
    width: 27vw;
    height: 80vh;
    /* Styles No Requireds */
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    padding: 20px;
}

.card div {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);;
    padding: 10px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 0 20px linear-gradient(to right, #ECF2FF, #FBFCFF);;
}

.card ul {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);;
    padding: 10px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 0 20px linear-gradient(to right, #ECF2FF, #FBFCFF);;
    overflow-x: auto;
}

.card li {
    margin-left: 15px;
    text-align: left;
    padding-top: 20px;
}

.header {
    margin-top: 40px;
    font-size: 24px;
    color: #235B5B;
    font-weight: bold;
    height: 50px;
}

.description {
    font-size: 16px;
    color: #235B5B;
    letter-spacing: .6px;
    line-height: 1.4rem;
}

@media screen and (max-width: 900px) {
    .card {
        width: 40vw;
        height: 80vh;
    }
    .mandatory {
        height: 80vh;
        padding-top: 100px !important;
    }
}

@media screen and (max-width: 700px) {
    .card {
        width: 40vw;
        height: 90vh;
    }
    .mandatory {
        height: 90vh;
        padding-top: 100px !important;
    }
}

@media screen and (max-width: 600px) {
    .card {
        width: 80vw;
        height: 70vh;
    }
    .mandatory {
        height: 70vh;
        padding-top: 100px !important;
    }
}

@media screen and (max-width: 500px) {
    .card {
        width: 80vw;
        height: 80vh;
    }
    .mandatory {
        height: 80vh;
        padding-top: 100px !important;
    }
}

@media screen and (max-width: 400px) {
    .card {
        width: 80vw;
        height: 90vh;
    }

    .mandatory {
        height: 90vh;
        padding-top: 100px !important;
    }
}